<template>
  <div>
    <v-container fluid>
      <Overlay :overlay="overlay"/>
      <ConfirmDialog ref="confirmDialog"/>
      <Lightbox ref="lightbox"/>

      <v-row justify="center">
        <v-col cols="12" lg="10">
          <v-card class="shadow rounded-lg">
            <v-card-title>
              Affectation
              <v-spacer/>

              <v-btn class="mr-2"
                     color="primary"
                     exact
                     left
                     text
                     to="/supervisor-view/campaigns">
                <v-icon color="primary" left>mdi-chevron-left</v-icon>
                Retour
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text>

              <div v-if="isLoading">
                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-skeleton-loader type="avatar,article"/>
                  </v-card-text>
                </v-card>
                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-skeleton-loader type="article,article,article,actions"/>
                  </v-card-text>
                </v-card>
              </div>


              <div v-else>

                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="12" lg="8" order="1" order-lg="0">
                        <div class="pb-3 font-weight-medium fs-17 grey--text text--darken-3">
                          {{ campaign.name }}
                        </div>

                        <div class="mb-3">

                          <div class="d-block text-no-wrap">
                            <v-icon color="primary" small>mdi-calendar</v-icon>
                            <span class="font-weight-medium"> Date de début : </span>
                            {{ $func.dateFormat(campaign.start_date) }}
                          </div>

                          <div class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-calendar</v-icon>
                            <span class="font-weight-medium"> Date de fin : </span>
                            {{ $func.dateFormat(campaign.end_date) }}
                          </div>

                          <div v-if="campaign.remaining_days"
                               class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-calendar-minus</v-icon>
                            <span class="font-weight-medium"> Jours restants : </span>
                            <v-chip :class="'bg-'+campaign.remaining_days.color+'-subtle'"
                                    :text-color="campaign.remaining_days.color"
                                    class="font-weight-medium fs-13"
                                    dark
                                    label
                                    small>
                              {{ campaign.remaining_days.value }}
                            </v-chip>
                          </div>

                          <div class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-tag</v-icon>
                            <span class="font-weight-medium"> Type : </span>
                            {{ $func.campaignType(campaign.type) }}
                          </div>

                        </div>

                        <p class="grey--text mb-0">
                          {{ campaign.description }}
                        </p>

                      </v-col>
                      <v-col class="text-center" cols="12" lg="4" order="0" order-lg="1">
                        <v-avatar rounded size="120">
                          <v-img v-if="campaign.image" :src="$baseUrl + campaign.image"
                                 class="zoom-pointer rounded-lg"
                                 max-width="130"
                                 min-height="130"
                                 @click="$refs.lightbox.open(campaign.image)"/>

                          <v-img v-else
                                 :src="require('@/assets/no-image.png')"
                                 class="rounded-lg"
                                 max-width="130"
                                 min-height="130"></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-alert v-if="errors" border="bottom" class="fs-14" dense dismissible prominent type="error">
                   <p class="fs-15 mb-1">
                     Ces points de vente ont été programmés avec des autres animateurs :
                   </p>
                  <ul>
                    <li v-for="(error,i) in errors" :key="i">
                      {{ error.toString() }}
                    </li>
                  </ul>
                </v-alert>

                <v-data-table
                        :headers="headers"
                        :items="animators"
                        class="table-border rounded-lg"
                        hide-default-footer
                >

                  <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center py-2">

                      <v-avatar v-if="item.photo" size="40">
                        <v-img :src="$baseUrl + item.photo"
                               class="zoom-pointer"
                               @click="$refs.lightbox.open(item.photo)"></v-img>
                      </v-avatar>

                      <v-avatar v-else
                                :class="$func.randomColor()"
                                class="font-weight-medium"
                                size="40">
                        {{ $func.avatarName(item.name) }}
                      </v-avatar>

                      <span class="ml-2">{{ item.name }}</span>
                    </div>
                  </template>

                  <template v-slot:item.is_selected="{ item }">
                    <v-switch v-model="item.is_selected" class="pa-0 ma-0" color="secondary" dense
                              hide-details/>
                  </template>

                  <template v-slot:item.email="{ item }">
                    <a :href="'mailto:'+item.email"
                       :style="{maxWidth: '150px'}"
                       class="d-block text-truncate orange--text text-decoration-none">
                      {{ item.email }}
                    </a>
                  </template>

                  <template v-slot:item.routing="{ item }">
                    <v-btn :color="countRouting(item.dates) ? 'secondary' : 'primary'"
                           :disabled="!item.is_selected"
                           depressed
                           small
                           @click="openRoutingDialog(item)">
                      Routing
                      <v-icon right small>
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </template>

                </v-data-table>

              </div>

            </v-card-text>

          </v-card>

          <div v-if="!isLoading" class="pt-5 text-right">
            <v-btn :disabled="!animators.filter(el=>el.is_selected === true).length"
                   :loading="overlay"
                   color="secondary"
                   depressed
                   @click="save">
              <v-icon left>mdi-content-save</v-icon>
              Enregistrer
            </v-btn>
          </div>

        </v-col>
      </v-row>

      <RoutingDialog v-if="animators.length"
                     ref="routingDialog"
                     :dates.sync="dates"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import RoutingDialog from "@/views/campaigns/create/components/RoutingDialog.vue";
import * as moment from "moment/moment";

export default {
    components: {RoutingDialog},
    data() {
        return {
            campaign: {},
            isLoading: false,
            overlay: false,
            animators: [],
            dates: [],
            errors: null,
        }
    },

    methods: {
        async save() {
            if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir enregistrer cette campagne ?', {
                color: 'orange',
                agreeText: 'Enregistrer',
                icon: 'mdi-information-outline',
            })) {
                this.errors = null
                this.overlay = true
                HTTP.post('/supervisor-view/campaigns/' + this.$route.params.id + '/store-assignment-animators', {
                    animators: this.animators
                }).then(() => {
                    this.overlay = false
                    this.$successMessage = "Cette campagne a été enregistré avec succés"
                    this.$router.push('/supervisor-view/campaigns')
                }).catch(err => {
                    this.overlay = false
                    if (err.response.status === 422) {
                        this.errors = err.response.data.message
                    }
                    console.log(err)
                })
            }
        },
        getCampaigns() {
            this.isLoading = true
            HTTP.get('/supervisor-view/campaigns/' + this.$route.params.id + '/assignment-animators').then((res) => {
                this.isLoading = false
                this.campaign = res.data.data.campaign
                let animators = res.data.data.animators

                /**
                 * *Pars data
                 */
                animators.map((ani) => {

                    let newArrDates = []
                    let campaignDates = this.getCampaignDates()

                    campaignDates.map(date => {
                        let findAniDate = ani.dates.find(aniDate => aniDate.date === date.date)
                        if (findAniDate) {
                            newArrDates.push({
                                date: findAniDate.date,
                                salepoints: findAniDate.salepoints,
                            })
                        } else {
                            newArrDates.push({
                                date: date.date,
                                salepoints: [],
                            })
                        }
                    })

                    ani.dates = newArrDates
                })

                this.animators = animators

            }).catch(err => {
                this.isLoading = false
                console.log(err)

                /**
                 * Not found ID
                 */
                if (err.response.status === 404) {
                    this.$router.push('/404')
                }
            })
        },
        openRoutingDialog(animator) {
            this.dates = animator.dates
            this.$refs.routingDialog.open(animator)
        },

        /**
         *
         * @returns {*[]}
         */
        getCampaignDates() {
            let dateArray = [];
            let startDate = moment(this.campaign.start_date);
            let stopDate = moment(this.campaign.end_date);

            while (startDate <= stopDate) {
                dateArray.push({
                    date: moment(startDate).format('YYYY-MM-DD'),
                    salepoints: [],
                })
                startDate = moment(startDate).add(1, 'days');
            }

            return dateArray
        },

        /**
         * @param dates
         * @returns {boolean}
         */
        countRouting(dates) {
            let res = false
            dates.map(date => {
                if (date.salepoints.length) {
                    res = true
                }
            })
            return res
        }
    },
    created() {
        this.getCampaigns()
    },
    computed: {
        headers() {
            return [
                {text: "Concerné", sortable: false, value: 'is_selected'},
                {text: "Nom d'animateur", sortable: false, value: 'name'},
                {text: "N°Téléphone", sortable: false, value: 'phone'},
                {text: "Email", sortable: false, value: 'email'},
                {text: "Routing", sortable: false, value: 'routing'},
            ]
        }
    }
}
</script>

<style scoped>

</style>